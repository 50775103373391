import { library } from "@fortawesome/fontawesome-svg-core";
import { faGlobe, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";

library.add(
  faGlobe,
  faApple,
  faAndroid,
  faSearch
);

const Icons = (props) => props.children;

export default Icons;

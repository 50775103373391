import React from "react";

const Card = ({ children, large, noPadding }) => {
  let styleClass = large ? "card-large" : "card";
  if (noPadding) {
    styleClass+=" card-hover"
    return (
      <div className={styleClass} style={{padding: "0px", overflow: "hidden"}}>
        {children}
      </div>
    )
  } else {
    return (
      <div className={styleClass}>
        {children}
      </div>
    )
  }
}

export default Card;
import React, { useState } from "react";
import Card from "../common/card";
import BgImage from "../../images/assets/bg-grid.svg";
import { useIntl, Link } from "gatsby-plugin-intl"

const Pricing = () => {
  const intl = useIntl()
  const [tier, setTier] = useState("basic");

  return (
    <div className="wrapper">
      <h3 className="header" style={{ paddingTop: "50px" }}>{intl.formatMessage({ id: "pricing.body.header" })}</h3>
      <div className="pricing-wrapper">
        <Card>
          <div className="card-wrapper">
            <h5>{intl.formatMessage({ id: "pricing.tier0.title" })}</h5>
            <p className="cost">{intl.formatMessage({ id: "pricing.tier0.price" })}<span>{intl.formatMessage({ id: "pricing.tier0.time" })}</span></p>
            <h6>{intl.formatMessage({ id: "pricing.tier0.subtitle" })}</h6>
            <ul>
              <li>{intl.formatMessage({ id: "pricing.tier0.feature1" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier0.feature2" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier0.feature3" })}</li>
            </ul>
          </div>
        </Card>
        <Card>
          <div className="card-wrapper">
            <h5>{intl.formatMessage({ id: "pricing.tier1.title" })}</h5>
            <p className="cost">{intl.formatMessage({ id: "pricing.tier1.price" })}<span>{intl.formatMessage({ id: "pricing.tier1.time" })}</span></p>
            <h6>{intl.formatMessage({ id: "pricing.tier1.subtitle" })}</h6>
            <ul>
              <li>{intl.formatMessage({ id: "pricing.tier1.feature1" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier1.feature2" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier1.feature3" })}</li>
            </ul>
          </div>
        </Card>
        <Card>
          <div className="card-wrapper">
            <p className="tier-popular">{intl.formatMessage({ id: "pricing.body.popular" })}</p>
            <h5>{intl.formatMessage({ id: "pricing.tier2.title" })}</h5>
            <p className="cost">{intl.formatMessage({ id: "pricing.tier2.price" })}<span>{intl.formatMessage({ id: "pricing.tier2.time" })}</span></p>
            <h6>{intl.formatMessage({ id: "pricing.tier1.subtitle" })}</h6>
            <ul>
              <li>{intl.formatMessage({ id: "pricing.tier2.feature1" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier2.feature2" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier2.feature3" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier2.feature4" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier2.feature5" })}</li>
            </ul>
          </div>
        </Card>
        <Card>
          <div className="card-wrapper">
            <h5>{intl.formatMessage({ id: "pricing.tier3.title" })}</h5>
            <p className="cost">{intl.formatMessage({ id: "pricing.tier3.price" })}<span>{intl.formatMessage({ id: "pricing.tier3.time" })}</span></p>
            <h6>{intl.formatMessage({ id: "pricing.tier3.subtitle" })}</h6>
            <ul>
              <li>{intl.formatMessage({ id: "pricing.tier3.feature1" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier3.feature2" })}</li>
              <li>{intl.formatMessage({ id: "pricing.tier3.feature3" })}</li>
            </ul>
          </div>
        </Card>
      </div>
      <div className="pricing-wrapper-mobile">
        <div className="pricing-link">
          <a className={`link${tier === "free" ? " link-active" : ""}`} onClick={() => setTier("free")}>{intl.formatMessage({ id: "pricing.tier0.title" })}</a>
          <a className={`link${tier === "basic" ? " link-active" : ""}`} onClick={() => setTier("basic")}>{intl.formatMessage({ id: "pricing.tier1.title" })}</a>
          <a className={`link${tier === "standard" ? " link-active" : ""}`} onClick={() => setTier("standard")}>{intl.formatMessage({ id: "pricing.tier2.title" })}</a>
          <a className={`link${tier === "premium" ? " link-active" : ""}`} onClick={() => setTier("premium")}>{intl.formatMessage({ id: "pricing.tier3.title" })}</a>
        </div>
        <div className="pricing-wrapper-mobile">
          {tier === "free" && <Card>
            <div className="card-wrapper">
              <h5>{intl.formatMessage({ id: "pricing.tier0.title" })}</h5>
              <p className="cost">{intl.formatMessage({ id: "pricing.tier0.price" })}<span>{intl.formatMessage({ id: "pricing.tier0.time" })}</span></p>
              <h6>{intl.formatMessage({ id: "pricing.tier0.subtitle" })}</h6>
              <ul>
                <li>{intl.formatMessage({ id: "pricing.tier0.feature1" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier0.feature2" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier0.feature3" })}</li>
                <li style={{ visibility: "hidden" }}>more</li>
              </ul>
            </div>
          </Card>}
          {tier === "basic" && <Card>
            <div className="card-wrapper">
              <h5>{intl.formatMessage({ id: "pricing.tier1.title" })}</h5>
              <p className="cost">{intl.formatMessage({ id: "pricing.tier1.price" })}<span>{intl.formatMessage({ id: "pricing.tier1.time" })}</span></p>
              <h6>{intl.formatMessage({ id: "pricing.tier1.subtitle" })}</h6>
              <ul>
                <li>{intl.formatMessage({ id: "pricing.tier1.feature1" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier1.feature2" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier1.feature3" })}</li>
                <li style={{ visibility: "hidden" }}>more</li>
              </ul>
            </div>
          </Card>}
          {tier === "standard" && <Card>
            <div className="card-wrapper">
              <p className="tier-popular">{intl.formatMessage({ id: "pricing.body.popular" })}</p>
              <h5>{intl.formatMessage({ id: "pricing.tier2.title" })}</h5>
              <p className="cost">{intl.formatMessage({ id: "pricing.tier2.price" })}<span>{intl.formatMessage({ id: "pricing.tier2.time" })}</span></p>
              <h6>{intl.formatMessage({ id: "pricing.tier2.subtitle" })}</h6>
              <ul>
                <li>{intl.formatMessage({ id: "pricing.tier2.feature1" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier2.feature2" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier2.feature3" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier2.feature4" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier2.feature5" })}</li>
              </ul>
            </div>
          </Card>}
          {tier === "premium" && <Card>
            <div className="card-wrapper">
              <h5>{intl.formatMessage({ id: "pricing.tier3.title" })}</h5>
              <p className="cost">{intl.formatMessage({ id: "pricing.tier3.price" })}<span>{intl.formatMessage({ id: "pricing.tier3.time" })}</span></p>
              <h6>{intl.formatMessage({ id: "pricing.tier3.subtitle" })}</h6>
              <ul>
                <li>{intl.formatMessage({ id: "pricing.tier3.feature1" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier3.feature2" })}</li>
                <li>{intl.formatMessage({ id: "pricing.tier3.feature3" })}</li>
                <li style={{ visibility: "hidden" }}>more</li>
              </ul>
            </div>
          </Card>}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <p style={{ marginBottom: "5px" }} className="secondary request-demo">{intl.formatMessage({ id: "pricing.body.demo" })}</p>
        <div style={{ marginTop: 30, marginBottom: 40 }}>
          <Link to="/signup" className="callout-button">{intl.formatMessage({ id: "pricing.body.tryApplication" })}</Link>
        </div>
        <Link to="/compare" className="link link-active">{intl.formatMessage({ id: "pricing.body.compare" })}</Link>
      </div>
      <BgImage className="bg-image" />
    </div>
  )
}

export default Pricing;
import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import Image from "../../images/front.png";

const Banner = () => {
  const intl = useIntl()
  return (
    <div className="wrapper">
      <div className="banner-wrapper">
        <h1 className="header">{intl.formatMessage({ id: "banner.body.header" })}</h1>
        <p>
          {intl.formatMessage({ id: "banner.body.headline" })}
        </p>
        <img src={Image} alt={intl.formatMessage({ id: "image.alt.banner" })} />
      </div>
    </div>
  )
}

export default Banner;
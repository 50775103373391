import React from "react";
import { useIntl } from "gatsby-plugin-intl"

const Priority = () => {
  const intl = useIntl()
  return (
    <>
      <h3 className="header" style={{paddingTop: "50px"}}>{intl.formatMessage({ id: "priority.header" })}</h3>
      <div>
        <div className="feature-finance-wrapper">
          <div className="wrapper">
            <div style={{marginTop: "40px"}}>
              <h4 className="header">{intl.formatMessage({ id: "Safety.body.header" })}</h4>
              <p>{intl.formatMessage({ id: "Safety.body.text1" })}</p>
              <h4 className="header">{intl.formatMessage({ id: "Personalized.body.header" })}</h4>
              <p>{intl.formatMessage({ id: "Personalized.body.text1" })}</p>
              <h4 className="header">{intl.formatMessage({ id: "Future-proof.body.header" })}</h4>
              <p>{intl.formatMessage({ id: "Future-proof.body.text1" })}</p>
              <h4 className="header">{intl.formatMessage({ id: "Updates.body.header" })}</h4>
              <p>{intl.formatMessage({ id: "Updates.body.text1" })}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Priority

import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl"

import CategoryImage from "../../images/insight.png";
import Image from "../../images/finance-image.png";

const Insight = () => {
  const intl = useIntl()
  return (
    <div>
      <div className="feature-insight-wrapper">
        <div className="wrapper">
          <div>
            <div className="feature-content">
              <div>
                <img className="insight-icon category-image" src={CategoryImage} alt={intl.formatMessage({ id: "image.alt.insight" })} />
                <span className="feature-category">{intl.formatMessage({ id: "insights.body.category" })}</span>
              </div>
              <div className="feature-mb-image"><img src={Image} alt={intl.formatMessage({ id: "image.alt.insight" })} /></div>
              <h1 className="header">{intl.formatMessage({ id: "insights.body.header" })}</h1>
              <p>{intl.formatMessage({ id: "insights.body.text1" })}</p>
              <p>{intl.formatMessage({ id: "insights.body.text2" })}</p>
              <Link className="link link-active" offset="93" to='/dashboard'>{intl.formatMessage({ id: "feature.readMore" })}</Link>
            </div>
            <div className="feature-image"><img src={Image} alt={intl.formatMessage({ id: "image.alt.insight" })} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Insight

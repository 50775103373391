import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";

import CategoryImage from "../../images/personal.png";
import Image from "../../images/personal-image.png";

const Presence = () => {
  const intl = useIntl()
  return (
    <div>
      <div className="feature-presentie-wrapper">
        <div className="wrapper">
          <div>
          <div className="feature-image"><img src={Image} alt={intl.formatMessage({ id: "image.alt.presentie" })} /></div>
            <div className="feature-content">
              <div>
                <img className="category-image" src={CategoryImage} alt={intl.formatMessage({ id: "image.alt.presentie" })} />
                <span className="feature-category">{intl.formatMessage({ id: "Presentie.body.category" })}</span>
              </div>
              <div className="feature-mb-image"><img src={Image} alt={intl.formatMessage({ id: "image.alt.presentie" })} /></div>
              <h1 className="header">{intl.formatMessage({ id: "Presentie.body.header" })}</h1>
              <p>{intl.formatMessage({ id: "Presentie.body.text1" })}</p>
              <p>{intl.formatMessage({ id: "Presentie.body.text2" })}</p>
              <Link className="link link-active" offset="93" to='/presentie'>{intl.formatMessage({ id: "feature.readMore" })}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Presence

import React from "react"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Pricing from "../components/sections/pricing"
import Application from "../components/sections/application"
import Pos from "../components/sections/feature-pos"
import Stock from "../components/sections/feature-stock"
import Presence from "../components/sections/feature-presence"
import Insight from "../components/sections/feature-insight"
import Priority from "../components/sections/priority"
import Banner from "../components/sections/banner"
import ContactForm from "../components/sections/contact-form"
import Icons from "../helpers/icon"

import { useIntl } from "gatsby-plugin-intl"

const IndexPage = () => {

  const intl = useIntl()
  
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "page.title.main" })} description={intl.formatMessage({ id: "page.description.main" })} />
      <Icons>
        <section>
          <Banner />
        </section>
        <section id="features-section">
          <Pos />
        </section>
        <section>
          <Stock />
        </section>
        <section>
          <Presence />
        </section>
        <section>
          <Insight />
        </section>
        <section>
          <Priority />
        </section>
        <section>
          <Application />
        </section>
        <section id="pricing-section">
          <Pricing />
        </section>
        <section id="contact-form">
          <ContactForm />
        </section>
      </Icons>
    </Layout>
  )
};

export default IndexPage

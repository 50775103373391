import React, { useState } from "react";
import Card from "../common/card";
import { useIntl, Link } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Application = () => {
  const intl = useIntl()
    const [tier, setTier] = useState("portal");

  return (
    <div className="wrapper">
      <h3 className="header" style={{ paddingTop: "50px" }}>{intl.formatMessage({ id: "application.header" })}</h3>
      <div className="pricing-wrapper" style={{marginTop: "100px"}}>
        <Link className="link" to="/dashboard">
          <Card noPadding>
            <div className="card-wrapper">
              <div className="application-card-portal"></div>
              <div className="application-title">
                <h5>{intl.formatMessage({ id: "application.portal" })}</h5>
                <FontAwesomeIcon icon="globe" />
              </div>
            </div> 
          </Card>
        </Link>
        <Link className="link" to="/presentie">
          <Card noPadding>
            <div className="card-wrapper">
              <div className="application-card-presentie"></div>
              <div className="application-title">
                <h5>{intl.formatMessage({ id: "application.presentie" })}</h5>
                <FontAwesomeIcon icon={["fab", "apple"]} />
                <FontAwesomeIcon icon={["fab", "android"]} />
              </div>
            </div> 
          </Card>
        </Link>
        <Link className="link" to="/griffier">
          <Card noPadding>
            <div className="card-wrapper">
              <div className="application-card-griffier"></div>
              <div className="application-title">
                <h5>{intl.formatMessage({ id: "application.griffier" })}</h5>
                <FontAwesomeIcon icon={["fab", "apple"]} />
                <FontAwesomeIcon icon={["fab", "android"]} />
              </div>
            </div> 
          </Card>
        </Link>
      </div>
      <div className="pricing-wrapper-mobile">
        <div className="pricing-link">
          <a className={`link${tier === "portal" ? " link-active" : ""}`} onClick={() => setTier("portal")}>{intl.formatMessage({ id: "application.portal" })}</a>
          <a className={`link${tier === "presentie" ? " link-active" : ""}`} onClick={() => setTier("presentie")}>{intl.formatMessage({ id: "application.presentie" })}</a>
          <a className={`link${tier === "griffier" ? " link-active" : ""}`} onClick={() => setTier("griffier")}>{intl.formatMessage({ id: "application.griffier" })}</a>
        </div>
        <div className="pricing-wrapper-mobile">
          {tier === "portal" && 
            <Link className="link" to="/dashboard">
              <Card noPadding>
                <div className="card-wrapper">
                  <div className="application-card-portal"></div>
                  <div className="application-title">
                    <h5>{intl.formatMessage({ id: "application.portal" })}</h5>
                    <FontAwesomeIcon icon="globe" />
                  </div>
                </div> 
              </Card>
            </Link>
          }
          {tier === "presentie" && 
            <Link className="link" to="/presentie">
              <Card noPadding>
                <div className="card-wrapper">
                  <div className="application-card-presentie"></div>
                  <div className="application-title">
                    <h5>{intl.formatMessage({ id: "application.presentie" })}</h5>
                    <FontAwesomeIcon icon={["fab", "apple"]} />
                    <FontAwesomeIcon icon={["fab", "android"]} />
                  </div>
                </div> 
              </Card>
            </Link>
          }
          {tier === "griffier" && 
            <Link className="link" to="/griffier">
              <Card noPadding>
                <div className="card-wrapper">
                  <div className="application-card-griffier"></div>
                  <div className="application-title">
                    <h5>{intl.formatMessage({ id: "application.griffier" })}</h5>
                    <FontAwesomeIcon icon={["fab", "apple"]} />
                    <FontAwesomeIcon icon={["fab", "android"]} />
                  </div>
                </div> 
              </Card>
            </Link>
          }
        </div>
      </div>
    </div>
  )
}

export default Application;
import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl"

import CategoryImage from "../../images/speed.png";
import Image from "../../images/speed-image.png";

const Pos = () => {
  const intl = useIntl()
  return (
    <>
      <h3 className="header" style={{paddingTop: "50px"}}>{intl.formatMessage({ id: "feature.header" })}</h3>
      <div className="feature-catalogue-wrapper">
        <div className="wrapper">
          <div>
            <div className="feature-image"><img src={Image} alt={intl.formatMessage({ id: "image.alt.pos" })} /></div>
            <div className="feature-content">
              <div>
                <img className="category-image" src={CategoryImage} alt={intl.formatMessage({ id: "image.alt.pos" })} />
                <span className="feature-category">{intl.formatMessage({ id: "pos.body.category" })}</span>
              </div>
              <div className="feature-mb-image"><img src={Image} alt={intl.formatMessage({ id: "image.alt.pos" })} /></div>
              <h1 className="header">{intl.formatMessage({ id: "pos.body.header" })}</h1>
              <p>{intl.formatMessage({ id: "pos.body.text1" })}</p>
              <Link className="link link-active" offset="93" to='/griffier'>{intl.formatMessage({ id: "feature.readMore" })}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pos;